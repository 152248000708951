import React, { useState, useEffect, useContext, useRef } from "react";
import SemiCircleProgressBar from "react-progressbar-semicircle";

//Material Components
import {
  Dialog,
  AppBar,
  Typography,
  Slide,
  IconButton,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadIcon from "@mui/icons-material/Download";
import { Hidden } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";

import { percentage_total } from "../../utils/helpers";
import { AuthContext } from "../../context/AuthProvider";
import Loading from "../../components/loading/Loading";
import { secondClient } from "../../index";
import { useQuery } from "@apollo/client";

import NewMeasurerDetails from "../highcharts/NewMeasurerDetails";
import { GET_METER_HISTORY_VARIABLES } from "../../controllers/measurersController";
//multilanguage
import { useTranslation } from "react-i18next";
import { addDays } from "date-fns";
import { Key } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#3f9948",
    position: "fixed",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const NewMacrometerCard = ({
  serial,
  model,
  alias,
  city,
  variable,
  startTimeProject,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    permissionsRoleUser,
    loadingPdf,
    setRangeDatePicker,
    rangeDatePicker,
    setRangeDatePickerToday,
    rangeDatePickerToday,
    dateReport,
  } = useContext(AuthContext);
  const [openNewMeasurer, setOpenNewMeasurer] = useState(false);
  const childRef = useRef();
  const day_before = new Date().getTime() - 86400000;

  const handleOpenMeasurer = () => {
    if ("data") {
      setOpenNewMeasurer(true);
      setRangeDatePicker([
        {
          ...rangeDatePicker.selection,
          startDate: new Date(),
          endDate: addDays(new Date(), 0),
          key: "selection",
        },
      ]);
      setRangeDatePickerToday([
        {
          ...rangeDatePickerToday.selection,
          startDate: new Date(),
          endDate: addDays(new Date(), 0),
          key: "selection",
        },
      ]);
    }
  }
  const handleCloseMeasurer = () => {
    
    setOpenNewMeasurer(false);
  };
  return (
    <>
      {alias ? (
        <>
          {" "}
          <div
            onClick={
              permissionsRoleUser?.permissionsRole?.MedMacVer
                ? handleOpenMeasurer
                : false
            }
            className="_measurer_container_"
          >
            <div className="_measurer_title_container_">
              <div className="_measurer__title_">
                <span>{alias ? alias : serial}</span>
              </div>
            </div>

            <div className="_measurer_container_kwh_">
              <div className="_measurer_data_container_">
                <span className="_measurer_data_number_">
                  {variable?.TImKwh}
                </span>
                <span className="_measurer_data_kwh_">kWh</span>
              </div>
            </div>
            <div className="_measurer_container_watts_">
              <SemiCircleProgressBar
                diameter={150}
                strokeWidth={15}
                stroke="rgba(162, 99, 239, 1)"
                background="rgba(162, 99, 239, 0.3)"
                percentage={`${percentage_total(variable?.EI)}`}
              />
            </div>
            <div className="_measurer_container_range_">
              <span className="_measurer__range_">0</span>
              <span className="_measurer__range_">100000</span>
            </div>
            <div className="_measurer_container_value_">
              {variable?.EI} Watts
            </div>
          </div>
          <Dialog
            fullScreen
            open={openNewMeasurer}
            onClose={handleCloseMeasurer}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseMeasurer}
                  aria-label="close"
                > 
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  {` ${localStorage.getItem("nameProject")} - ${t(
                    "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_HEADER_MODAL.TITLE"
                  )} ${serial} `}
                </Typography>

                <Hidden mdDown>
                  <LoadingButton
                    // disabled={roomPdf.rooms.length == 0 || roomPdf == undefined}
                    onClick={() => childRef.current.macrometerPdf()}
                    endIcon={<DownloadIcon />}
                    loadingPosition="end"
                    color="inherit"
                  >
                    {t(
                      "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_HEADER_MODAL.DOWNLOAD"
                    )}
                  </LoadingButton>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme?.zIndex?.drawer + 1,
                    }}
                    open={loadingPdf ? true : false}
                    onClick={loadingPdf}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Hidden>
              </Toolbar>
            </AppBar>

            <NewMeasurerDetails
              city={city}
              dato={variable}
              serial={serial}
              startTimeProject={startTimeProject}
              ref={childRef}
            />
          </Dialog>{" "}
        </>
      ) : null}
    </>
  );
};
