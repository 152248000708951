import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

//Global context
import { AuthContext } from "../../context/AuthProvider";

//Material UI components
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import { TextField } from "@material-ui/core";
import { ListItemsAreas } from "../listItems/ListItemsAreas";

//GraphQL Hooks & Queries
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_ALL_LOGGED_USER_PROJECT } from "../../controllers/projectController";

//multilanguage
import { useTranslation } from "react-i18next";

const FilterProject = ({
  user,
  projects,
  showPagination,
  // refetch,
  dataProjects,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setProject, setProjectID, permissionsRoleUser } = useContext(AuthContext);
  const { data, loading, error, refetch } = useQuery(
    GET_ALL_LOGGED_USER_PROJECT,
    {
      fetchPolicy: "no-cache",
      variables: {
        projectManagementId: parseInt(localStorage.getItem("np")),
      },
    }
  );
  const [value, setValue] = React.useState();
  const proyectos = projects;
  const [found, setFound] = React.useState();
  const imgProject = data?.projectManagement.find((element) => element.id == found?.id)?.img;
  localStorage.setItem("nameProject", found?.name);
  localStorage.setItem("imgProject", imgProject);

  useEffect(() => {
    if (value) {
      DatasProjects();
    }
  }, [value]);

  useEffect(() => {
    if (found) {
      navigateProject();
    }
  }, [found]);

  const DatasProjects = () => {
    const resultado = proyectos.find((element) => element.id == value.id);
    setFound(resultado);
  };
  const navigateProject = () => {
    setProjectID(found.id);
    history.push("/app/areas/" + found.id);
  };

  const handleDeleteProject = () => {
    localStorage.setItem("nameProject", "");
    sessionStorage.removeItem("resultadoFinal");
    sessionStorage.removeItem("macrometerArgos");
    window.location.reload();
  };

  return (
    <div className="filterproject_container">
      <div className="filterproject_title">
        <span>
          {t("VIEW_PROJECT.COMPONENT_PROJECT_FILTER.WELCOME")}, {user}
        </span>
      </div>
      <div className="filterproject_filter_search_container">
        {permissionsRoleUser?.permissionsRole?.proy ? (
          <Autocomplete
            size="small"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              localStorage.setItem("id", newValue.id);
              localStorage.setItem(
                "project_selected",
                JSON.stringify(
                  dataProjects?.getAllProjectsName?.find(
                    (i) => i.id === newValue.id
                  )
                )
              );
              setProject([
                dataProjects?.getAllProjectsName?.find(
                  (i) => i.id === newValue.id
                ),
              ]);
            }}
            id="combo-box-demo"
            options={projects}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => (
              <React.Fragment>
                <TextField
                  sx={{ margiButton: "15px", border: "#21A11D" }}
                  {...params}
                  style={{ height: 30 }}
                  label={t(
                    "VIEW_PROJECT.COMPONENT_PROJECT_FILTER.PROJECT_FILTER_PLACEHOLDER"
                  )}
                  variant="outlined"
                />
              </React.Fragment>
            )}
          />
        ) : null}

        {!showPagination && (
          <Chip
            style={{ backgroundColor: "#21A11D", color: "white" }}
            label={localStorage.getItem("nameProject")}
            onDelete={handleDeleteProject}
          />
        )}
      </div>
    </div>
  );
};

export default FilterProject;
