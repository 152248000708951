import React, { useContext, useState } from "react";
import { ListItemIcon, ListItem, ListItemText, List } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import ControlCameraIcon from "@material-ui/icons/ControlCamera";
import SpeedIcon from "@material-ui/icons/Speed";
import Collapse from "@material-ui/core/Collapse";
import { handleSortAlphabet } from "../../utils/helpers";
import { AuthContext } from "../../context/AuthProvider";

//query and mutation
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_ROOMS_BY_PROJECT } from "../../controllers/roomControllers";

//multilanguage
import { useTranslation } from "react-i18next";

export const ListItemsAreas = () => {
  const { t } = useTranslation();

  const { project, projects, permissionsRoleUser } = useContext(AuthContext);
  const [openProjects, setOpenProjects] = React.useState(false);
  const project__id = Number(localStorage.getItem("id"));

  const { data, loading, error } = useQuery(GET_ALL_ROOMS_BY_PROJECT, {
    variables: { ProjectId: project__id, id: project__id },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: false,
  });

  

  const listMacromeasureOption = (
    (data?.getMettersInProjets?.[0]?.Metters?.length > 0 || data?.getInfoMetersByProject?.length > 0) &&
    sessionStorage.getItem("resultadoFinal") !== null
  ) || (
    data?.getMettersInProjets?.[0]?.Metters?.length > 0 &&
    sessionStorage.getItem("macrometerArgos") !== null
  );
    

  const change_project_id = (id) => {
    localStorage.setItem("id", id);
  };
  let probando = localStorage.getItem("id");
  let location = useLocation();
  const imgProject = localStorage.getItem("imgProject");
  return (
    <>
      {permissionsRoleUser?.permissionsRole?.proy ? (
        <div>
          <Link to={"/app/projects"} className="_sidebar_item">
            <ListItem
              button
              onClick={() => {
                setOpenProjects(!openProjects)
                sessionStorage.removeItem("resultadoFinal")
                sessionStorage.removeItem("macrometerArgos")
              }}
              className={`${
                location.pathname === "/app/projects"
                  ? "__on_item"
                  : "__off_item"
              }`}
            >
              {" "}
              <ListItemIcon className="_dashboard_icon">
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("VIEW_PROJECT.COMPONENT_SIDEBAR.PROJECTS")}
              />
            </ListItem>
          </Link>
          {location.pathname !== "/app/projects" && probando !== null ? (
            <div>
              <Link to={"/app/areas/" + probando} className="_sidebar_item">
                <ListItem button onClick={() => change_project_id(probando)}>
                  <ListItemIcon className="_listitem_icon">
                    <img src={imgProject} alt="Img" width={50} height={50} />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${localStorage.getItem("nameProject")}`}
                    className="_listitem_name_projects"
                  />
                </ListItem>
              </Link>
              <Collapse in={true} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {permissionsRoleUser?.permissionsRole?.Area &&
                  localStorage.getItem("id") ? (
                    <Link
                      to={"/app/areas/" + probando}
                      className="_sidebar_item"
                    >
                      <ListItem
                        button
                        onClick={() => change_project_id(probando)}
                        className={`${
                          location.pathname === "/app/areas/" + probando
                            ? "__on_item"
                            : "__off_item"
                        }`}
                      >
                        <ListItemIcon className="__icon_hab_and_control">
                          <HomeWorkIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={t("VIEW_PROJECT.COMPONENT_SIDEBAR.AREAS")}
                          className="_sidebar_text"
                        />
                      </ListItem>
                    </Link>
                  ) : null}
                  {permissionsRoleUser?.permissionsRole?.Ctl ? (
                    <Link
                      to={"/app/controllers/" + probando}
                      className="_sidebar_item"
                    >
                      <ListItem
                        button
                        onClick={() => change_project_id(probando)}
                        className={`${
                          location.pathname === "/app/controllers/" + probando
                            ? "__on_item"
                            : "__off_item"
                        }`}
                      >
                        <ListItemIcon className="__icon_hab_and_control">
                          <ControlCameraIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={t(
                            "VIEW_PROJECT.COMPONENT_SIDEBAR.CONTROLLERS"
                          )}
                        />
                      </ListItem>
                    </Link>
                  ) : null}
                  {permissionsRoleUser?.permissionsRole.Med &&
                  listMacromeasureOption ? (
                    <Link
                      to={"/app/measurers/" + probando}
                      className="_sidebar_item"
                    >
                      <ListItem
                        button
                        onClick={() => change_project_id(probando)}
                        className={`${
                          location.pathname === "/app/measurers/" + probando
                            ? "__on_item"
                            : "__off_item"
                        }`}
                      >
                        <ListItemIcon className="__icon_hab_and_control">
                          <SpeedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t(
                            "VIEW_PROJECT.COMPONENT_SIDEBAR.MEASURERS"
                          )}/>
                      </ListItem>
                    </Link>
                  ) : null}
                </List>
              </Collapse>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : null}
    </>
  );
};
