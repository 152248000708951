import gql from "graphql-tag";

export const LOGIN = gql`
  mutation ($user: String, $password: String, $device: String) {
    Login(user: $user, password: $password, device: $device) {
      id
      name
      last_name
      token
      email
      security
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ($user: String, $email: String) {
    send_Email(user: $user, email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ($token: String!, $password: String!, $confirmPassword:String!) {
    create_Reg_Password(token: $token, password: $password, confirmPassword:$confirmPassword)
  }
`;

export const LOGOUT = gql`
  mutation Mutation {
    logout
}`
