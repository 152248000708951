import { useQuery, useLazyQuery } from "@apollo/client"
import { GET_MACROMETER_IN_PROJECT } from "../../../controllers/measurersController"
import { useState, useEffect } from "react"

export const useGetDataMetters = () => {
    const id = parseInt(localStorage.getItem('id'))

    const {data, loading, error, refetch} = useQuery(GET_MACROMETER_IN_PROJECT, {
        fetchPolicy:"network-only",
        variables:{ProjectId: id}
    })
    
    const [dataMacrometer, setDataMacrometer] =useState()
     const metters =  data?.getMettersInProjets?.map((i) => i.Metters)
     const macrometers = metters?.flat()
     const datoMacrometer = macrometers?.map(i =>({
        serial : i?.serial,
        alias : i?.alias,
        modelo: i.model
     })) 
     useEffect(() => {
        setDataMacrometer(datoMacrometer)
     }, [data])
     return( dataMacrometer)
    }