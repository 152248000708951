import React, { useState, useEffect, useContext, useRef } from 'react';
import SemiCircleProgressBar from 'react-progressbar-semicircle';
import { Dialog, AppBar, Typography, Slide, IconButton, Toolbar, makeStyles } from '@material-ui/core';
import { calc_percent_watts, cacl_color_graph_measurer } from '../../utils/helpers';
import CloseIcon from '@material-ui/icons/Close';
import MeasurerPhases from '../highcharts/MeasurerPhases';
import { AuthContext } from '../../context/AuthProvider';
import { addDays } from 'date-fns';
import Button from '@material-ui/core/Button';
import html2canvas from 'html2canvas';
import { Hidden } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

//multilanguage
import { useTranslation } from 'react-i18next';
import NewMeasurerPhases from '../highcharts/NewMeasurerPhases/NewMeasurerPhases';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#3f9948',
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

//Meters-{id proyecto}

const Measurer = ({ data, refetch, timeZone }) => {
  const { t } = useTranslation();
  const { event, setDateReport, setactiveNextBack, loadingPdf, setLoadingPdf, permissionsRoleUser } = useContext(AuthContext);
  const [openMeasurer, setOpenMeasurer] = useState(false);
  const classes = useStyles();
  const childRef = useRef();

  const typeGateway = data?.Room?.Controller?.typeGateway;

  // useEffect(() => {
  //     if(data.id == event.id_device){
  //         refetch()
  //     }
  // }, [event])

  const handleClose = () => {
    setOpenMeasurer(false);
    setactiveNextBack(false);
  };

  const handleOpenReportPhases = () => {
    setOpenMeasurer(true);
    setactiveNextBack(true);
    setDateReport([
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 0),
        key: 'selection',
      },
    ]);
  };

  return (
    <>
      <div
        onClick={permissionsRoleUser?.permissionsRole?.MedRpfVer ? handleOpenReportPhases : false}
        className="_measurer_container_"
      >
        <div className="_measurer_title_container_">
          <div className="_measurer__title_">
            <span>{data?.alias}</span>
          </div>
        </div>
        <div className="_measurer_container_kwh_">
          <div className="_measurer_data_container_">
            <span className="_measurer_data_number_">{Number(`${data?.id == event.id_device && event.action == 'KWH' ? event.status : data?.variables?.KWH}`).toFixed(1)}</span>
            <span className="_measurer_data_kwh_">kWh</span>
          </div>
        </div>
        <div className="_measurer_container_watts_">
          <SemiCircleProgressBar
            diameter={150}
            stroke={cacl_color_graph_measurer(data?.variables?.Watts)}
            percentage={`${calc_percent_watts(`${data?.id == event.id_device && event.action == 'Watts' ? event.status : data?.variables?.Watts}`)}`}
          />
        </div>
        <div className="_measurer_container_range_">
          <span className="_measurer__range_">0</span>
          <span className="_measurer__range_">15000</span>
        </div>
        <div className="_measurer_container_value_">{Number(`${data?.id == event.id_device && event.action == 'Watts' ? event.status : data?.variables?.Watts}`).toFixed(1) | 0} Watts</div>
      </div>
      <Dialog
        fullScreen
        open={openMeasurer}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
              className={classes.title}
            >
              {` ${localStorage.getItem('nameProject')} - ${t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_HEADER_MODAL.DESCRIPTION_TITLE')} ${data?.alias}`}
            </Typography>
            {permissionsRoleUser?.permissionsRole?.MedRpfDes ? (
              <Hidden mdDown>
                <LoadingButton
                  onClick={() => childRef.current.measurersPhases()}
                  endIcon={<DownloadIcon />}
                  loadingPosition="end"
                  color="inherit"
                >
                  {t(
                    "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_HEADER_MODAL.DOWNLOAD"
                  )}
                </LoadingButton>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={loadingPdf}
                  onClick={loadingPdf}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Hidden>
            ) : null}
          </Toolbar>
        </AppBar>
        {typeGateway === 'horus_700' ? (
          <NewMeasurerPhases
            measurer={data}
            timeZone={timeZone}
          />
        ) : (
          <MeasurerPhases
            {...{
              timeZone,
            }}
            measurer={data}
            ref={childRef}
          />
        )}
      </Dialog>
    </>
  );
};

export default Measurer;
